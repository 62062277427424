import React from 'react'
import { FiCheck, FiLoader } from 'react-icons/fi'

export default function Checkbox({ checked, onClick, className, loading }) {
  return (
    <div
      onClick={!loading && onClick}
      className={`flex justify-center bg-white items-center flex-shrink-0 w-8 h-8 border shadow-sm rounded-sm border-gray-100 text-gray-300 cursor-pointer hover:border-gray-200 ${className}`}>
      {loading && <FiLoader className="h-4 w-4 stroke-1 rotating box-border" />}
      {!loading && checked && <FiCheck className="stroke-2 w-4 h-4" />}
    </div>
  )
}
